var traduction = {
  0: {
    LANG: "FR",
    QUICK: "JOUER",
    DAY: "DEFI DU JOUR",
    CUSTOM: "PARTIE PERSONNALISEE",
    ABOUT: "A PROPOS DU JEU",
    LOAD: "CHARGER",
    ERROR_UNABLE: "IMPOSSIBLE DE CHARGER UNE PARTIE",
    COMPETITIVE: "COMPETITIF",
    SOLO: "SOLO / COOP",
    CLASSIC: "Classique",
    EXTREME: "Extrême",
    NIGHTMARE: "Cauchemar",
    CLASSICSUB: "",
    EXTREMESUB: "(Voir la règle)",
    NIGHTMARESUB: "(Voir la règle)",
    EASY: "Facile",
    MEDIUM: "Moyen",
    HARD: "Difficile",
    V4: "4 vérificateurs",
    V5: "5 vérificateurs",
    V6: "6 vérificateurs",
    PLAY: "GENERER",
    LOADING: "CHARGEMENT...",
    CHECKCODE: "TESTER UN CODE",
    INPUTCODE: "SAISIR LE CODE",
    TESTCODE: "VERIFICATION",
    FALSECODE: "CE N'EST PAS LE CODE !!!",
    GOODCODE: "C'EST LE BON CODE !!!",
    INPUTSOLOA: "Maintenant, pour savoir si vous avez battu la machine,",
    INPUTSOLOB: "Saisissez vos questions posées :",
    CLICBOXES: "Cliquez sur les cases pour les saisir",
    MAX3: "3 questions maximum par round !",
    ADDROUND: "Ajouter un round",
    DELROUND: "Supprimer le dernier round",
    SUBMITSOLOQUESTIONS: "SOUMETTRE",
    SOCIALWIN: "J'ai battu la machine !",
    SOCIALTIE: "Match nul contre la machine !",
    SOCIALLOSE: "La machine m'a battu !",
    YOUWIN: "VICTOIRE !!!",
    CANCEL: "RETOUR",
    SOLUTION: "SOLUTION",
    BACK: "RETOUR",
    SHOW_SOLUTION: "Etes-vous sûr ?",
    YES: "OUI",
    NO: "NON",
    CODE: "CODE",
    SHEET: "FEUILLE DE NOTES",
    SEARCH: "# Chercher une partie",
    STEP1: "MODE",
    STEP2: "DIFFICULTE",
    STEP3: "DUREE",
    CRITERIA: "Cartes Critères",
    CRITERIADOUBLE: "Cartes Critères (2 par vérificateur)",
    CRITERIAMIXED: "Cartes Critères (mélangées)",
    VERIFIER: "Cartes Vérification",
    SOLOMODE: "(Mode solo)",
    CHECKCODESOLO: "MACHINE",
    INPUTROUND: "NOMBRE DE ROUNDS",
    INPUTQUESTION: "NOMBRE DE QUESTIONS",
    WINSOLO0: "VOUS AVEZ TROUVE LE CODE MAIS PAS BATTU LA MACHINE",
    WINSOLO1: "VICTOIRE ! MEME SCORE QUE LA MACHINE !",
    WINSOLO2: "VICTOIRE ! VOUS AVEZ BATTU LA MACHINE !!!",
    ABOUTLINK: "http://www.scorpionmasque.com/fr/turingmachine",
    DATEFORMAT: "DD/MM/YYYY"
  },
  1: {
    LANG: "EN",
    QUICK: "PLAY",
    DAY: "DAILY CHALLENGE",
    CUSTOM: "CUSTOM GAME",
    ABOUT: "ABOUT THE GAME",
    LOAD: "LOAD",
    ERROR_UNABLE: "UNABLE TO LOAD GAME",
    COMPETITIVE: "COMPETITIVE",
    SOLO: "SOLO / COOP",
    CLASSIC: "Classic",
    EXTREME: "Extreme",
    NIGHTMARE: "Nightmare",
    CLASSICSUB: "",
    EXTREMESUB: "(See rulebook)",
    NIGHTMARESUB: "(See rulebook)",
    EASY: "Easy",
    MEDIUM: "Standard ",
    HARD: "Hard",
    V4: "4 cards",
    V5: "5 cards",
    V6: "6 cards",
    PLAY: "GENERATE",
    LOADING: "LOADING...",
    CHECKCODE: "TEST A CODE",
    INPUTCODE: "INPUT YOUR CODE",
    TESTCODE: "VERIFY",
    FALSECODE: "This code is not correct",
    GOODCODE: "This is the correct code!!!",
    INPUTSOLOA: "Now, to know if you beat the machine,",
    INPUTSOLOB: "input your question marks:",
    CLICBOXES: "Click on the boxes to fill them",
    MAX3: "3 questions maximum per round!",
    ADDROUND: "Add a round",
    DELROUND: "Remove last round",
    SUBMITSOLOQUESTIONS: "SUBMIT",
    SOCIALWIN: "I BEAT THE MACHINE!",
    SOCIALTIE: "I TIED THE MACHINE!",
    SOCIALLOSE: "THE MACHINE BEATS ME!",
    YOUWIN: "VICTORY!!!",
    CANCEL: "BACK",
    SOLUTION: "SOLUTION",
    BACK: "BACK",
    SHOW_SOLUTION: "Are you sure?",
    YES: "YES",
    NO: "NO",
    CODE: "CODE",
    SHEET: "NOTE SHEET",
    SEARCH: "# Search a problem",
    STEP1: "MODE",
    STEP2: "DIFFICULTY",
    STEP3: "DURATION",
    CRITERIA: "Criteria Cards",
    CRITERIADOUBLE: "Criteria Cards (2 on each verifier)",
    CRITERIAMIXED: "Criteria Cards (mixed)",
    VERIFIER: "Verification Cards",
    SOLOMODE: "(Solo mode)",
    CHECKCODESOLO: "MACHINE",
    INPUTROUND: "TOTAL ROUNDS PLAYED",
    INPUTQUESTION: "TOTAL QUESTIONS ASKED",
    WINSOLO0: "YOU FOUND THE CODE... BUT YOU DIDN'T BEAT THE MACHINE",
    WINSOLO1: "VICTORY!!! YOU TIED THE MACHINE!!!",
    WINSOLO2: "VICTORY!!! YOU BEAT THE MACHINE!!!",
    ABOUTLINK: "http://www.scorpionmasque.com/en/turingmachine",
    DATEFORMAT: "MM/DD/YYYY"
  }
};

export default traduction;
